import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../api';
import { toast } from "react-toastify";

const CarousalListTable = () => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    const fetchCarousals = async () => {
        try {
            const response = await Axios.get('/api/carousels');
            setTableData(response.data);
        } catch (error) {
            console.log(error);
            toast.error('Error fetching carousals');
        }
    };

    useEffect(() => {
        fetchCarousals();
    }, []);

    const handleDelete = async (id) => {
        try {
            await Axios.delete(`/api/carousels/${id}`);
            setTableData(prevData => prevData.filter(carousal => carousal._id !== id));
            toast.success('Carousal deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('Error deleting carousal');
        }
    };

    const columns = [
        { field: 'image', headerName: 'Image', flex: 1, minWidth: 200, renderCell: (params) => <img src={params.row.image} alt={params.row.heading} style={{ width: '100px' }} /> },
        { field: 'heading', headerName: 'Heading', flex: 1, minWidth: 150 },
        { field: 'para', headerName: 'Paragraph', flex: 2, minWidth: 300 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div className="cellAction">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(params.row._id)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(`/carousals/edit/${params.row._id}`)}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="datatable">
            <div className="datatableTitle">
                <Link to={`/carousals/add`} className="link">
                    Add New Carousal
                </Link>
            </div>
            <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
                checkboxSelection
            />
        </div>
    );
};

export default CarousalListTable;
