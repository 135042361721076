import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import "./carousels.scss";
import Axios from '../../api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const CarousalEditForm = () => {
    const { id } = useParams();
    const initialImageDetail = { url: '' };
    const [formData, setFormData] = useState({
        image: '',
        heading: '',
        para: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.put(`/api/carousels/${id}`, formData);
            if (response.status === 200) {
                toast.success('Carousal details updated successfully', { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error('Failed to update carousal details.', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while updating the carousal details.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    useEffect(() => {
        async function fetchCarousalDetails() {
            try {
                const response = await Axios.get(`/api/carousels/${id}`);
                if (response.status === 200) {
                    const carousalData = response.data;
                    setFormData(carousalData);
                } else {
                    toast.error('Failed to fetch carousal details.', { position: toast.POSITION.TOP_RIGHT });
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred while fetching carousal details.', { position: toast.POSITION.TOP_RIGHT });
            }
        }

        fetchCarousalDetails();
    }, [id]);

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Edit Carousal Details</h2>
                    <div className="form-group">
                        <label>Image URL</label>
                        <input
                            type="text"
                            name="image"
                            value={formData.image}
                            onChange={handleChange}
                            required
                        />

                        <label>Heading</label>
                        <input
                            type="text"
                            name="heading"
                            value={formData.heading}
                            onChange={handleChange}
                            required
                        />

                        <label>Paragraph</label>
                        <textarea
                            name="para"
                            value={formData.para}
                            onChange={handleChange}
                            required
                        />

                        <button type="submit" className="btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CarousalEditForm;
