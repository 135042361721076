import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import Axios from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = 'RUG-';
    for (let i = 0; i < 8; i++) {
        code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
};

const initialImage = { id: 0, url: '' };

const initialProductData = {
    name: '',
    details: '',
    image: [initialImage],
    price: '',
    slug: { current: '' },
    category: '',
    stock: '',
    description: '',
    productCode: generateUniqueCode(),
    designStory: ''
};

const ProductForm = ({ fetchData }) => {
    const [formData, setFormData] = useState(initialProductData);

    useEffect(() => {
        console.log(formData, "fetching details...");
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (e, index) => {
        const { value } = e.target;
        const updatedImages = [...formData.image];
        updatedImages[index].url = value;
        setFormData({
            ...formData,
            image: updatedImages
        });
    };

    const handleAddImage = () => {
        setFormData({
            ...formData,
            image: [...formData.image, { ...initialImage, id: formData.image.length }]
        });
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...formData.image];
        updatedImages.splice(index, 1);
        setFormData({
            ...formData,
            image: updatedImages
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.post('/api/products', formData);
            setFormData(initialProductData);
            fetchData();
            toast.success('Product submitted successfully', { position: toast.POSITION.TOP_RIGHT });

        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Rugs Product Management</h2>
                    <div className="form-group">
                        <label>Category</label>
                        <select
                            name="category"
                            value={formData.category}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="rugs">Rugs</option>
                        </select>

                        <label>Product Code</label>
                        <input
                            type="text"
                            name="productCode"
                            value={formData.productCode}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Stock</label>
                        <input
                            type="number"
                            name="stock"
                            value={formData.stock}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Description</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Design Story</label>
                        <textarea
                            name="designStory"
                            value={formData.designStory}
                            onChange={handleInputChange}

                        />

                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Details</label>
                        <textarea
                            name="details"
                            value={formData.details}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Price</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Slug</label>
                        <input
                            type="text"
                            name="slug.current"
                            value={formData.slug.current}
                            onChange={(e) => {
                                const { value } = e.target;
                                setFormData({
                                    ...formData,
                                    slug: { current: value }
                                });
                            }}
                            required
                        />

                        {formData.image.map((img, index) => (
                            <div key={index} className="image-details">
                                <label>Image URL {index + 1}</label>
                                <input
                                    type="text"
                                    name="url"
                                    value={img.url}
                                    onChange={(e) => handleImageChange(e, index)}
                                    required
                                />
                                {formData.image.length > 1 && (
                                    <button type="button" className='btn' onClick={() => handleRemoveImage(index)}>
                                        Remove Image
                                    </button>
                                )}
                            </div>
                        ))}

                        <button type="button" className='btn' onClick={handleAddImage}>
                            Add Image
                        </button>

                        <button type="submit" className='btn'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductForm;
