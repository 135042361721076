import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import Axios from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialCarousalData = {
    image: '',
    heading: '',
    para: '',
};

const CarousalForm = ({ fetchData }) => {
    const [formData, setFormData] = useState(initialCarousalData);

    useEffect(() => {
        console.log(formData, "fetching details...");
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.post('/api/carousels/add', formData);
            setFormData(initialCarousalData);
            fetchData();
            toast.success('Carousal submitted successfully', { position: toast.POSITION.TOP_RIGHT });

        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Carousal Management</h2>
                    <div className="form-group">
                        <label>Image URL</label>
                        <input
                            type="text"
                            name="image"
                            value={formData.image}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Heading</label>
                        <input
                            type="text"
                            name="heading"
                            value={formData.heading}
                            onChange={handleInputChange}
                            required
                        />

                        <label>Paragraph</label>
                        <textarea
                            name="para"
                            value={formData.para}
                            onChange={handleInputChange}
                            required
                        />

                        <button type="submit" className='btn'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CarousalForm;
