import React, { useState } from 'react';
import Axios from '../../api';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import "./tripItinerary.scss";

const TripItinerary = () => {

    const initialContent = {
        leftContent: [
        ],
        rightContent: [
        ]
    };
    const initialHighlights = {
        leftColumn: [

        ],
        rightColumn: [

        ]
    };
    const initialDayByDay = {
        day: 1,
        description: "<strong></strong>",
        activities: [
            "<p></p>"
        ]
    };

    const initialCustomizationContent = {
        heading: ["A JOURNEY BUILT JUST FOR YOU"],
        description: ["Take customization to the next level on a journey fully tailored to you. MidAsia's destination specialists will gladly tailor this existing itinerary—or build one completely from scratch—to suit your style, interests, and time frame. Contact us to see what's possible."]
    };

    const initialFormData = {
        tripName: "",
        tripRegion: "",
        tripTitle: '',
        tripSubtitle: '',
        tripHeadingCultureTitle: '',
        tripHeadingCulture: '',
        tripImage: '',
        mapIframeSrc: '',
        tripDescription: initialContent,
        highlights: initialHighlights,
        factsAndInclusions: [],
        dayByDay: [initialDayByDay],
        images: [],
        customizationDescription: initialCustomizationContent
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isEdit, setIsEdit] = useState(false);
    const [imageFile, setImageFile] = useState(null);

    const handleChange = (e, section, index, field, nestedIndex) => {
        const { name, value } = e.target;

        if (section) {
            const updatedSection = [...formData[section]];

            if (nestedIndex !== undefined) {
                updatedSection[index][field][nestedIndex] = value;
            } else if (field) {
                updatedSection[index][field] = value;
            } else {
                updatedSection[index] = value;
            }

            setFormData({ ...formData, [section]: updatedSection });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleAddItem = (section, initialItem) => {
        const updatedSection = [...formData[section], initialItem];
        setFormData({ ...formData, [section]: updatedSection });
    };
    const handleRemoveItem = (section, index, field, currIndexDay) => {
        const updatedSection = [...formData[section]];
        if (currIndexDay !== undefined && updatedSection[currIndexDay][field].length > 1) {
            updatedSection[currIndexDay][field].splice(index, 1);
        }

        setFormData({ ...formData, [section]: updatedSection });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.post('/api/itinerary/trips', formData);
            if (response.status >= 200 && response.status < 300) {
                setFormData(initialFormData);
                toast.success('Data submitted successfully', { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error('Server error. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const handleAddItem1 = (section, field) => {
        const updatedSection = { ...formData[section] };
        updatedSection[field] = [...updatedSection[field], ''];
        setFormData({ ...formData, [section]: updatedSection });
    };

    const handleRemoveItem1 = (section, field, index) => {
        console.log(index);
        const updatedSection = { ...formData[section] };
        updatedSection[field].splice(index, 1);
        setFormData({ ...formData, [section]: updatedSection });
    };


    const handleDynamicChange = (section, field, index, value) => {
        const updatedSection = formData[section] ? { ...formData[section] } : {};
        if (!updatedSection[field]) updatedSection[field] = [];
        updatedSection[field][index] = value;
        setFormData({ ...formData, [section]: updatedSection });
    };

    const handleAddActivity = (section, index, field, currIndexDay) => {
        console.log(section, index, field, currIndexDay)
        const updatedSection = [...formData[section]];
        updatedSection[currIndexDay][field].push('');
        console.log(updatedSection[currIndexDay])
        setFormData({ ...formData, [section]: updatedSection });
    }

    const handleDayRemove = (section, index) => {
        const updatedSection = [...formData[section]];
        updatedSection.splice(index, 1);
        setFormData({ ...formData, [section]: updatedSection });
    }

    const handleRemoveImageItem = (section, index) => {
        const updatedSection = [...formData[section]];
        updatedSection.splice(index, 1);
        setFormData({ ...formData, [section]: updatedSection });
    }

    const handleRemoveFactsInclution = (section, index) => {
        const updatedSection = [...formData[section]];
        updatedSection.splice(index, 1);
        setFormData({ ...formData, [section]: updatedSection });
    }

    const handleImageUpload = async () => {
        if (!imageFile) {
            toast.error('Please select an image first', { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        const formData = new FormData();
        formData.append('file', imageFile);
        formData.append('upload_preset', 'your_upload_preset');
        formData.append('cloud_name', 'your_cloud_name');

        try {
            const response = await Axios.post('https://api.cloudinary.com/v1_1/your_cloud_name/image/upload', formData);
            if (response.status === 200) {
                const newImage = {
                    original: response.data.url,
                    thumbnail: response.data.secure_url
                };
                setFormData(prevFormData => ({
                    ...prevFormData,
                    images: [...prevFormData.images, newImage]
                }));
                toast.success('Image uploaded successfully', { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error('Image upload failed', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Image upload error:', error);
            toast.error('An error occurred during image upload', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Itinerary Trip Management</h2>

                    <label>Trip Name</label>
                    <input
                        type="text"
                        name="tripName"
                        value={formData.tripName}
                        onChange={handleChange}
                        required
                    />
                    <label>Trip Region</label>
                    <input
                        type="text"
                        name="tripRegion"
                        value={formData.tripRegion}
                        onChange={handleChange}
                        required
                    />
                    <label>Trip Title</label>
                    <input
                        type="text"
                        name="tripTitle"
                        value={formData.tripTitle}
                        onChange={handleChange}
                        required
                    />

                    <label>Trip Subtitle</label>
                    <input
                        type="text"
                        name="tripSubtitle"
                        value={formData.tripSubtitle}
                        onChange={handleChange}
                        required
                    />

                    <label>Trip Image</label>
                    <input
                        type="text"
                        name="tripImage"
                        value={formData.tripImage}
                        onChange={handleChange}
                        required
                    />
                    {/* <button type="button" onClick={handleImageUpload}>Upload Image</button> */}
                    <label>Trip Heading Culture Title</label>
                    <input
                        type="text"
                        name="tripHeadingCultureTitle"
                        value={formData.tripHeadingCultureTitle}
                        onChange={handleChange}
                        required
                    />

                    <label>Trip Heading Culture</label>
                    <input
                        type="text"
                        name="tripHeadingCulture"
                        value={formData.tripHeadingCulture}
                        onChange={handleChange}
                        required
                    />

                    <label>Map Iframe Src</label>
                    <textarea
                        name="mapIframeSrc"
                        value={formData.mapIframeSrc}
                        onChange={handleChange}
                        required
                    />

                    <h3>Trip Description</h3>
                    {['leftContent', 'rightContent'].map((field) => (
                        <div key={field}>
                            <label>{field}</label>
                            {formData.tripDescription[field].map((item, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        value={item}
                                        onChange={(e) => handleDynamicChange('tripDescription', field, index, e.target.value)}
                                        required
                                    />
                                    <button type="button" onClick={() => handleRemoveItem1('tripDescription', field, index)}>Remove</button>
                                </div>
                            ))}
                            <button type="button" onClick={() => handleAddItem1('tripDescription', field)}>Add Trip Description</button>
                        </div>
                    ))}

                    <h3>Highlights</h3>
                    {['leftColumn', 'rightColumn'].map((field) => (
                        <div key={field}>
                            <label>{field}</label>
                            {formData.highlights[field].map((item, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        value={item}
                                        onChange={(e) => handleDynamicChange('highlights', field, index, e.target.value)}
                                        required
                                    />
                                    <button type="button" onClick={() => handleRemoveItem1('highlights', field, index)}>Remove</button>
                                </div>
                            ))}
                            <button type="button" onClick={() => handleAddItem1('highlights', field)}>Add Highlight</button>
                        </div>
                    ))}


                    <h3>Facts and Inclusions</h3>
                    {formData.factsAndInclusions.map((item, index) => (
                        <div key={index}>
                            <textarea
                                type="text"
                                value={item}
                                onChange={(e) => handleChange(e, 'factsAndInclusions', index)}
                                required
                            />
                            <button type="button" onClick={() => handleRemoveFactsInclution('factsAndInclusions', index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => handleAddItem('factsAndInclusions', '')}>Add Fact/Inclusion</button>

                    <h3>Day by Day</h3>
                    {formData.dayByDay.map((item, index) => (
                        <div key={index}>
                            <label>Day</label>
                            <input
                                type="text"
                                value={item.day}
                                onChange={(e) => handleChange(e, 'dayByDay', index, 'day')}
                                required
                            />
                            <label>Description</label>
                            <input
                                type="text"
                                value={item.description}
                                onChange={(e) => handleChange(e, 'dayByDay', index, 'description')}
                                required
                            />
                            {item.activities.map((activity, actIndex) => (
                                <div key={actIndex}>
                                    <label>Activity</label>
                                    <textarea
                                        type="text"
                                        value={activity}
                                        onChange={(e) => handleChange(e, 'dayByDay', index, 'activities', actIndex)}
                                        required
                                    />

                                    <button type="button" onClick={() => handleRemoveItem('dayByDay', actIndex, 'activities', index)}>Remove Activity</button>
                                    <button type="button" onClick={() => handleAddActivity('dayByDay', item.activities.length, 'activities', index)}>Add Activity</button>
                                </div>
                            ))}

                            <button type="button" onClick={() => handleDayRemove('dayByDay', index)}>Remove Day</button>

                        </div>
                    ))}
                    <button type="button" onClick={() => handleAddItem('dayByDay', {
                        day: formData.dayByDay.length + 1, description: "<strong></strong>",
                        activities: [
                            "<p></p>"
                        ]
                    })}>Add Day</button>

                    <h3>Images</h3>
                    {formData.images.map((image, index) => (
                        <div key={index}>
                            <label>Original</label>
                            <input
                                type="text"
                                value={image.original}
                                onChange={(e) => handleChange(e, 'images', index, 'original')}
                                required
                            />
                            <label>Thumbnail</label>
                            <input
                                type="text"
                                value={image.thumbnail}
                                onChange={(e) => handleChange(e, 'images', index, 'thumbnail')}
                                required
                            />
                            <button type="button"
                                onClick={() => handleRemoveImageItem('images', index)}>Remove Image</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => handleAddItem('images', { original: '', thumbnail: '' })}>Add Image</button>


                    <h3>Customization Content</h3>
                    <label>Heading</label>
                    <input
                        type="text"
                        name="customizationHeading"
                        value={formData.customizationDescription?.heading[0] || ''}
                        onChange={(e) => handleDynamicChange('customizationDescription', 'heading', 0, e.target.value)}
                        required
                    />
                    <label>Description</label>
                    <textarea
                        name="customizationDescription"
                        value={formData.customizationDescription?.description[0] || ''}
                        onChange={(e) => handleDynamicChange('customizationDescription', 'description', 0, e.target.value)}
                        required
                    />

                    <button type="submit">Submit</button>
                </form>
            </div >
        </div >
    );
};

export default TripItinerary;
